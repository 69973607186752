import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import { PostNode } from "../markdown-remark-types"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fixed(width: 450) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        client
      }
    }
  }
`

interface MarkdownRemarkNode {
  markdownRemark: PostNode
}

interface BlogPostProps {
  data: MarkdownRemarkNode
}

export default (props: BlogPostProps) => {
  const post = props.data.markdownRemark
  return (
    <Layout>
      <h1>{post.frontmatter.title}</h1>
      <h2>Client: {post.frontmatter.client}</h2>
      <Img
        fadeIn={true}
        fixed={(post.frontmatter.featuredImage! as any).childImageSharp.fixed}
      />
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </Layout>
  )
}
